declare global {
  interface Window {
    plugins?: any;
  }
}

export interface CordovaGooglePayPlugin {
  /**
   * This method lets an application read the wallet ID, and
   * calls the success callback function with the ID
   */
  getActiveWallet: (
    success: (id: string) => void,
    error: (e: string) => void
  ) => void;

  /**
   * This method will create an empty wallet for the user. It
   * will start a user interaction activity to confirm google
   * account and permissions for creating the wallet, then call
   * the success callback with the wallet ID
   */
  createWallet: (
    success: (id: string) => void,
    error: (e: string) => void
  ) => void;

  /**
   * This method calls the success callback with the Stable
   * Hardware ID for the phone if successful
   */
  getDeviceId: (
    success: (id: string) => void,
    error: (e: string) => void
  ) => void;

  /**
   * This method sends a boolean in the success callback to
   * indicate whether or not a device can be setup with Google Pay
   */
  isAvailable: (
    success: (isAvailable: boolean) => void,
    error: (e: string) => void
  ) => void;

  /**
   * This method checks to see if a card's token is still valid in
   * the user's active wallet on the device using Google Pay. The
   * token is identified by a TokenRefId passed in as the input, and
   * the card association input tells the plugin which token provider
   * to validate with indicate whether or not a device can be setup
   * with Google Pay
   */
  isValidToken: (
    succes: (isValid: boolean) => void,
    error: (e: string) => void,
    refId: string,
    association: string
  ) => void;

  /**
   * This method pushes the input card and user information into the
   * Google Pay Wallet. It will start a user interaction activity
   * to confirm or add any missing address information
   */
  addCard: (
    success: () => void,
    error: (e: string) => void,
    encryptedCardData: string,
    association: string,
    lastFour: string,
    addressOne: string,
    addressTwo: string | undefined,
    country: string,
    city: string,
    state: string,
    name: string,
    phone: string | undefined,
    zip: string
  ) => void;
}

export interface GooglePayPlugin {
  /**
   * Get available called to check whether
   * Google Pay is available based on device
   * and Returns `true` if available else `false`.
   */
  getGooglePayAvailable(): Promise<boolean>;
  getGoogleActiveWallet(): Promise<string>;
  createGoogleWallet(): Promise<string>;
  getGoogleDeviceId(): Promise<string>;
  isGoogleValidToken(refId: string, association: string): Promise<boolean>;
  addToGoogleWallet(
    encryptedCardData: string,
    association: string,
    lastFour: string,
    addressOne: string,
    addressTwo: string | undefined,
    country: string,
    city: string,
    state: string,
    name: string,
    phone: string | undefined,
    zip: string
  ): Promise<string>;
}

export class GooglePayCordovaPlugin implements GooglePayPlugin {
  constructor(private readonly googlePayPlugin: CordovaGooglePayPlugin) {}

  getGooglePayAvailable(): Promise<boolean> {
    return new Promise((resolve) => {
      this.googlePayPlugin.isAvailable(
        (type: boolean) => resolve(type),
        () => resolve(false)
      );
    });
  }

  getGoogleActiveWallet(): Promise<string> {
    return new Promise((resolve) => {
      this.googlePayPlugin.getActiveWallet(
        (type: string) => resolve(type),
        (e) => resolve(e)
      );
    });
  }

  createGoogleWallet(): Promise<string> {
    return new Promise((resolve) => {
      this.googlePayPlugin.createWallet(
        (type: string) => resolve(type),
        (e) => resolve(e)
      );
    });
  }

  getGoogleDeviceId(): Promise<string> {
    return new Promise((resolve) => {
      this.googlePayPlugin.getDeviceId(
        (type: string) => resolve(type),
        (e) => resolve(e)
      );
    });
  }

  isGoogleValidToken(refId: string, association: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.googlePayPlugin.isValidToken(
        (type: boolean) => resolve(type),
        () => resolve(false),
        refId,
        association
      );
    });
  }

  addToGoogleWallet(
    encryptedCardData: string,
    association: string,
    lastFour: string,
    addressOne: string,
    addressTwo: string | undefined,
    country: string,
    city: string,
    state: string,
    name: string,
    phone: string | undefined,
    zip: string
  ): Promise<string> {
    return new Promise((resolve) => {
      this.googlePayPlugin.addCard(
        () => resolve('success'), // success
        (e) => resolve(e), // failure
        encryptedCardData,
        association,
        lastFour,
        addressOne,
        addressTwo,
        country,
        city,
        state,
        name,
        phone,
        zip
      );
    });
  }
}

export class GooglePayCordovaNoopPlugin implements GooglePayPlugin {
  getGooglePayAvailable(): Promise<boolean> {
    return Promise.resolve(false);
  }
  getGoogleDeviceId(): Promise<string> {
    return Promise.resolve('');
  }
  getGoogleActiveWallet(): Promise<string> {
    return Promise.resolve('');
  }
  createGoogleWallet(): Promise<string> {
    return Promise.resolve('');
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isGoogleValidToken(refId: string, association: string): Promise<boolean> {
    return Promise.resolve(false);
  }
  addToGoogleWallet(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    encryptedCardData: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    association: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    lastFour: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addressOne: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addressTwo: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    country: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    city: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    name: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    phone: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    zip: string
  ): Promise<string> {
    return Promise.resolve('success');
  }
}
